import React from "react";

import moment from "moment/moment";
import { NavLink } from "react-router-dom";

import MessageBox from "./MessageBox";
import { Carousel } from "react-responsive-carousel";
import useUpcomingEvents from "../features/useUpcomingEvents";
import Spinner from "../UI/Spinner";

const UpcomingEventsAll = ({ config }) => {
  const { events, isLoading, error } = useUpcomingEvents();

  if (isLoading) return <Spinner />;
  if (error) return <MessageBox>{error.message}</MessageBox>;

  console.log("test");

  const images = [];
  let filterData = [];
  if (config.home) {
    filterData = events.filter((item) => item.is_all === true);
  } else if (config.faculty) {
    filterData = events.filter((item) =>
      item.faculty.some((i) => i.collegecode === config.faculty)
    );
  } else if (config.department) {
    filterData = events.filter((item) =>
      item.department.some((i) => i.depid === config.department)
    );
  }

  const ErrorMessage = <p className="heading4">There is no Upcoming Events</p>;

  const EventsList = filterData.map((mylist) => {
    return (
      <div className="card-body" key={mylist.id}>
        <div className="events-date">
          <p className="date">{moment(mylist.startDate).date()}</p>
          <p className="month">{moment(mylist.startDate).format("MMM")}</p>
        </div>
        <div className="events-description text-left">
          <p className="card-title heading5">
            <NavLink exact to={"/upcomingEvents/" + mylist.id}>
              {mylist.Headline}
            </NavLink>
          </p>
          <p className="allText">
            {`${mylist.details.substring(0, 60)}... `}
            <NavLink exact to={"/upcomingEvents/" + mylist.id}>
              More Info
            </NavLink>
          </p>
        </div>
      </div>
    );
  });

  return (
    <div className="card">
      {EventsList.length <= 0 ? ErrorMessage : EventsList}
      <Carousel
        autoPlay={true}
        showArrows={true}
        showIndicators={true}
        showThumbs={false}
        showStatus={false}
        infiniteLoop={true}
        interval={3000}
        stopOnHover={true}
      >
        {images}
      </Carousel>
    </div>
  );
};

export default UpcomingEventsAll;

import React from "react";

import { Link } from "react-router-dom";
import LeftSideBar from "../component/NewsAndUpdatesSideBar";
import Footer from "../../component/Footer";
import moment from "moment/moment";

import LoadingBox from "../../component/LoadingBox";
import MessageBox from "../../component/MessageBox";
import Pagination from "../../UI/Pagination";
import useNewsArchive from "../../features/useNewsArchive";

const News = () => {
  const { news, isLoading, error, count } = useNewsArchive();
  if (isLoading) return <LoadingBox />;
  if (error) return <MessageBox>{error.message}</MessageBox>;

  const newsDiv =
    news &&
    news.map((newsList) => {
      return (
        <div className="card" key={newsList.id}>
          <img
            className="img-fuild"
            src={newsList.headerImage}
            alt={newsList.headerImage}
          ></img>
          <div className="card-body">
            <div className="newsFooter">
              <div className="newsHeadlines">
                <Link exact to={"/news/" + newsList.id}>
                  <p className="heading5">{newsList.headline}</p>
                </Link>
                <p className="publsihDate">
                  {moment(newsList.newsDate, "YYYY-MM-DD").format("LL")}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    });

  return (
    <>
      <div className="content">
        <div className="OtherPagecontent">
          <hr></hr>
          <p className="heading2 text-center primaryHeading">News</p>
          <hr></hr>
          <div id="newsAll">{newsDiv}</div>
          <Pagination count={count} />
        </div>
        <LeftSideBar />
      </div>
      <Footer />
    </>
  );
};

export default News;

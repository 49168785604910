import React from "react";
import { NavLink } from "react-router-dom";
import { Dropdown, Menu } from "semantic-ui-react";
import { departmentCode } from "../../../util";

const leftSideNavigationEnglish = () => {
  return (
    <div id="facultyLeftSideMenu" className="sidemenu">
      <Menu vertical className="sidemenuBar">
        <Dropdown text="Home" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            <Dropdown.Item>
              <NavLink exact to="/departmentOfCSE">
                CSE Home
              </NavLink>
            </Dropdown.Item>
            <Dropdown.Item>
              <NavLink exact to="/">
                EU Home
              </NavLink>
            </Dropdown.Item>

            <Dropdown text="About" className="link item sideMenuBarDropdown">
              <Dropdown.Menu>
                <Dropdown.Item>
                  <NavLink exact to="/welcomeMessageCSE">
                    Welcome Message
                  </NavLink>
                </Dropdown.Item>
                <Dropdown.Item>
                  <NavLink exact to="/exploringCSE">
                    Exploring CSE EU
                  </NavLink>
                </Dropdown.Item>
                <Dropdown.Item>
                  <NavLink exact to="/clubInformationCSE">
                    Clubs Information
                  </NavLink>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown text="Academic" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            {/* Programs */}
            <Dropdown text="Programs" className="link item sideMenuBarDropdown">
              <Dropdown.Menu>
                <Dropdown text="Undergraduate" className="link item">
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <NavLink exact to="/bscinCSEDepartmentProgram">
                        B.Sc in CSE
                      </NavLink>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Dropdown.Menu>
            </Dropdown>

            {/* Admission */}
            <Dropdown
              text="Admission"
              className="link item sideMenuBarDropdown"
            >
              <Dropdown.Menu>
                <Dropdown.Item>
                  <NavLink exact to="/admissionCSEDepartment">
                    Undergraduate
                  </NavLink>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {/* Curriculum */}
            <Dropdown
              text="Curriculum"
              className="link item sideMenuBarDropdown"
            >
              <Dropdown.Menu>
                <Dropdown.Item>
                  <NavLink exact to="/underGraduateCurriculumCSE">
                    Undergraduate
                  </NavLink>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown text="People" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            {/* Faculty Members */}
            {/* <Dropdown
              text="Faculty Members"
              className="link item sideMenuBarDropdown"
            >
              <Dropdown.Menu>
                <Dropdown.Item>
                  <NavLink exact to="/atAGlanceFacultyMembersCSE">
                    At A Glance
                  </NavLink>
                </Dropdown.Item>
                <Dropdown.Item>
                  <NavLink exact to="/currentFacultyMembersCSE">
                    Current Faculty{" "}
                  </NavLink>
                </Dropdown.Item>
                <Dropdown.Item>
                  <NavLink exact to="/adjunctCurrentFacultyMembersCSE">
                    Adjunct Faculty
                  </NavLink>
                </Dropdown.Item>
                <Dropdown.Item>
                  <NavLink exact to="/onLeaveFacultyMembersCSE">
                    On Leave
                  </NavLink>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
            {/* Officials */}
            <Dropdown.Item>
              <NavLink exact to="/atAGlanceFacultyMembersCSE">
                Faculty Members
              </NavLink>
            </Dropdown.Item>
            <Dropdown.Item>
              <NavLink exact to="/officialsCSE">
                Officials
              </NavLink>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {/* Lab */}
        <Menu.Item className="sideBarSingleItem">
          <NavLink exact to="/labFacilitiesCSE">
            Lab Facilities
          </NavLink>
        </Menu.Item>

        <Dropdown text="Research" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            <Dropdown.Item>
              {/* <NavLink exact to='/publicationsCSE'>Publications</NavLink> */}
              <a
                href="http://dspace.easternuni.edu.bd:8080/xmlui/"
                target="_blank"
                rel="noreferrer"
              >
                Publications
              </a>
            </Dropdown.Item>
            <Dropdown.Item>
              <NavLink exact to="/researchGroupCSE">
                Research Groups
              </NavLink>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {/* Announcement */}
        <Dropdown text="Announcement" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            <Dropdown.Item>
              <NavLink exact to="/newsCSE">
                News
              </NavLink>
            </Dropdown.Item>
            <Dropdown.Item>
              <NavLink exact to="/upcomingEventsCSE">
                Upcoming Events
              </NavLink>
            </Dropdown.Item>
            <Dropdown.Item>
              <NavLink exact to="/noticesCSE" tabindex="-1">
                Notice
              </NavLink>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Menu.Item className="sideBarSingleItem">
          {/* <NavLink exact to='/alumniCSE'>Alumni</NavLink> */}
          <a
            href={`http://webportal.easternuni.edu.bd/Online_Alumni/_AlumniSearch.aspx?department=${departmentCode.cse}`}
            target="_blank"
          >
            Alumni
          </a>
        </Menu.Item>
        <Menu.Item className="sideBarSingleItemLast">
          <NavLink exact to="/contactUsCSE">
            Contact Us
          </NavLink>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default leftSideNavigationEnglish;

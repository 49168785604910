import React from "react";

import Wrapper from "../component/Wrapper";
import {
  Administrative_Position_EU_Career,
  Faculty_Position_EU_Career,
  On_campus_job_Form,
  Messenger,
  careerImgOnCampus,
} from "../static";

const Career = () => {
  const facultyForm = <a href={Faculty_Position_EU_Career}>Faculty</a>;
  const adminForm = (
    <a href={Administrative_Position_EU_Career}>Administration</a>
  );
  const onCampusForm = <a href={On_campus_job_Form}>On Campus</a>;
  const mlssForm = <a href={Messenger}>MLSS Form</a>;
  return (
    <Wrapper>
      <div id="career">
        <div className="career">
          <p className="heading1 text-center">Career Opportunity</p>
          <hr></hr>
          <>
            <div className="CareerLinks">
              <p className="heading5">Download Application form :</p>
              <ul style={{ listStyleType: "none" }}>
                <li>
                  <a href={Faculty_Position_EU_Career}>Faculty Position ||</a>
                </li>
                <li>
                  <a href={Administrative_Position_EU_Career}>
                    Administrative Position ||
                  </a>
                </li>
                <li>
                  <a href={On_campus_job_Form}>
                    On Campus Job (Only for Registered Student) ||
                  </a>
                </li>
                <li>
                  <a href={Messenger}>
                    MLSS Position (For Messenger, Driver, Electrician, Gardener,
                    etc)
                  </a>
                </li>
              </ul>
            </div>
            {/* <img className="img-fluid" src={careerImgMLSS} alt="Career" /> */}

            <div className="posts mb-4">
              <table className="table table-hover mb-4 tb-4">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Position</th>
                    <th scope="col">For Details please click the below </th>
                    <th scope="col">Download Form</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr className="table-success">
                    <th scope="row" rowspan="2">
                      1
                    </th>
                    <td rowspan="2">অফিস সাপোর্ট স্টাফ</td>
                    <td>
                      <a href="assets/Career/Bus_Helper.pdf">বাস হেলপার</a>
                    </td>
                    <td>{mlssForm}</td>
                  </tr>
                  <tr>
                    <td>
                      <a href="assets/Career/Gardener.pdf">মালি</a>
                    </td>
                    <td>{mlssForm}</td>
                  </tr> */}

                  <tr className="table-warning">
                    <th scope="row" rowspan="2">
                      1
                    </th>

                    <td rowspan="2">Faculty Positions</td>
                    <td>
                      <a href="assets/Career/Lecturer _(Finance).pdf">
                        Lecturer (Finance): Faculty of Business Administration
                      </a>
                    </td>
                    <td>{facultyForm}</td>
                  </tr>

                  <tr>
                    <td>
                      <a href="assets/Career/Lecturer_(Management).pdf">
                        Lecturer (Management): Faculty of Business
                        Administration
                      </a>
                    </td>
                    <td>{facultyForm}</td>
                  </tr>

                  <td colSpan={4}>
                    <p className="heading5">Read Before Apply</p>
                    <ul className="text-left pl-2">
                      <li>
                        For details please visit EU website:
                        www.easternuni.edu.bd/career or Bdjobs.com
                      </li>
                      <li>
                        Salary is negotiable for highly deserving candidates
                      </li>
                      <li>Computer literacy is essential for the position</li>
                      <li>
                        Experience and requirements may be relaxed otherwise
                        exceptionally qualified candidates
                      </li>
                      <li>
                        Only shortlisted candidates will be called for written
                        test or interview
                      </li>
                      <li>
                        If the applicant does not apply in the prescribed form
                        of the university, the application may be rejected
                      </li>
                      <li>
                        Eastern University reserves the right not to appoint
                        anyone if suitable candidates are not found
                      </li>
                      <li>
                        Any prior communication or persuasion about the
                        recruitment will be considered as disqualification
                      </li>
                    </ul>
                    <strong>
                      <i>*Photograph must be enclosed with the resume. </i>
                    </strong>
                  </td>

                  {/* <tr>
                    <th scope="row">3</th>
                    <td>MLSS Position</td>
                    <td>
                      <a href="assets/Career/support_staff.pdf">
                      অফিস সাপোর্ট স্টাফ
                      </a>
                    </td>
                    <td>{mlssForm}</td>
                  </tr> */}

                  {/* <tr>
                    <td colSpan={4}>
                      <p className="allText">
                        {" "}
                        <strong>*For administrative positions,</strong> attach a
                        bank draft/pay order of Tk. 200/- (Two hundred only)
                        payable in favor of Eastern University with hard copy
                        application, or bKash Tk.200/- to 01795711361 and send
                        the TrxID along with the online application form.
                      </p>
                      <p className="allText">
                        You can make payments from your bKash Account to any
                        &#39;Merchant&#39; who accepts &#39;bKash Payment&#39;.
                      </p>
                      <ol start="1" className="text-left ml-3">
                        <li>Go to your bKash Mobile Menu by dialling *247#</li>
                        <li>Choose &quot;Payment&quot;</li>
                        <li>
                          Enter the Merchant bKash Account Number you want to
                          pay to
                        </li>
                        <li>Enter the amount you want to pay</li>
                        <li>
                          Enter a reference* against your payment (you can
                          mention the purpose of the transaction in one word.
                          e.g. pay orders)
                        </li>
                        <li>
                          Enter the Counter Number* (the salesperson at the
                          counter will tell you the number)
                        </li>
                        <li>Now enter your bKash Mobile Menu PIN to confirm</li>
                      </ol>

                      <p className="allText">
                        Done! You will receive a confirmation message from
                        bKash.
                      </p>

                      <p className="heading5">Read Before Apply</p>
                      <ul className="text-left ml-2">
                        <li>
                          Rank and Salary are negotiable for highly deserving
                          candidates
                        </li>
                        <li>
                          Computer literacy are essential for the position
                        </li>
                        <li>
                          Experience and requirements are may be relaxed
                          otherwise exceptionally qualifies candidates
                        </li>
                        <li>
                          Only shortlisted candidates will be called for
                          interview
                        </li>
                        <li>
                          If the applicant does not apply in the prescribed form
                          of the university the application will be rejected
                        </li>
                      </ul>
                      <strong>
                        <i>*Photograph must be enclosed with the resume. </i>
                      </strong>
                    </td>
                  </tr> */}
                </tbody>
              </table>
              {/* <table className="table table-hover mb-4 tb-4">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Position</th>
                    <th scope="col">For Details please click the below </th>
                    <th scope="col">Download Form</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="table-success">
                    <th scope="row" rowspan="3">
                      1
                    </th>
                    <td rowspan="3">অফিস সাপোর্ট স্টাফ</td>
                    <td>
                      <a href="assets/Career/driver.pdf">
                        ড্রাইভার (বাস, মাইক্রোবাস ও প্রাইভেটকার)
                      </a>
                    </td>

                    <td>{mlssForm}</td>
                  </tr>
                  <tr>
                    <td>
                      <a href="assets/Career/Bus_Helper.pdf">বাস হেলপার</a>
                    </td>
                    <td>{mlssForm}</td>
                  </tr>
                  <tr>
                    <td>
                      <a href="assets/Career/Gardener.pdf">মালি</a>
                    </td>
                    <td>{mlssForm}</td>
                  </tr>
                </tbody>
              </table> */}
            </div>

            {/* <div className="requirments text-center">
              <p className="heading3 text-center">আবেদনপত্র পাঠানোর ঠিকানাঃ</p>
              <p className="allText text-center">
                মানবসম্পদ বিভাগ, ইস্টার্ন ইউনিভার্সিটি ক্যাম্পাস
              </p>
              <p className="allText text-center">
                সড়ক ৬, ব্লক বি, আশুলিয়া মডেল টাউন, খাগান, বিরুলিয়া, সাভার, ঢাকা
                ১২১৬
              </p>
              <p className="allText text-center">or </p>
              <p className="allText text-center">send the hard copy to</p>{" "}
              <p className="allText text-center">
                এছাড়া ই-মেইলের মাধ্যমে (jobs@easternuni.edu.bd) ছবিসহ নির্ধারিত
                ফর্মে আবেদনপত্র পাঠাতে পারবেন। খামের উপর পদের নাম উল্লেখ করতে
                হবে।
              </p>
              <p
                className="allText text-center"
                style={{ backgroundColor: "tomato", color: "white" }}
              >
                <strong>আবেদনপত্র জমা দেয়ার শেষ তারিখ ২২ জুন ২০২৪</strong>
              </p>
            </div> */}

            <div className="requirments text-center">
              <p className="heading3 text-center">Apply Procedure</p>
              <p className="allText text-center">To apply, </p>
              <p className="allText text-center">
                please download the application form from{" "}
                <strong>
                  <a href="https://www.easternuni.edu.bd/career">
                    www.easternuni.edu.bd/career
                  </a>
                </strong>{" "}
                fill-up the form and attach it to your email message and
              </p>
              <p className="allText text-center">
                send it to{" "}
                <strong>
                  <a href="mailto:jobs@easternuni.edu.bd">
                    {" "}
                    jobs@easternuni.edu.bd,{" "}
                  </a>
                </strong>{" "}
                email subject should be `Position Name`
              </p>
              <p className="allText text-center">or </p>
              <p className="allText text-center">send the hard copy to</p>{" "}
              <p className="allText text-center">
                {" "}
                <strong>The Registrar </strong>{" "}
              </p>
              <p className="allText text-center">
                {" "}
                <strong>Eastern University</strong>{" "}
              </p>
              <p className="allText text-center">
                {" "}
                <strong>
                  Road 6, Block B, Ashulia Model Town, Ashulia, Savar,
                  Dhaka-1345.{" "}
                </strong>
              </p>
              <p className="allText text-center">
                Mention the position on top of the envelope.
              </p>
              <p
                className="allText text-center"
                style={{ backgroundColor: "tomato", color: "white" }}
              >
                <strong>
                  Applications should be submitted by 15 July 2024
                </strong>
              </p>
            </div>

            {/* <img
              className="img-fluid"
              src={careerImgOnCampus}
              alt="career on campus"
            /> */}
          </>
        </div>
      </div>
    </Wrapper>
  );
};

export default Career;

import React from 'react'
import { NavLink } from 'react-router-dom';
import { Dropdown, Menu } from 'semantic-ui-react'

const SideBar = () => {
    return (
        <div id='facultyLeftSideMenu' className='sidemenu'>
            <Menu vertical className='sidemenuBar'>
                <Dropdown text='Home' className='link item sideMenuBarDropdown'>
                    <Dropdown.Menu>
                        <Dropdown.Item>
                            <NavLink exact to='/departmentOfEEE'>EEE Home</NavLink></Dropdown.Item>
                        <Dropdown.Item>
                            <NavLink exact to='/'>EU Home</NavLink>
                        </Dropdown.Item>

                        <Dropdown text='About' className='link item sideMenuBarDropdown'>
                            <Dropdown.Menu>
                                <Dropdown.Item>
                                    <NavLink exact to='/welcomeMessageEEE'>Welcome Message</NavLink>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <NavLink exact to='/exploringEEE'>Exploring EEE EU</NavLink>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <NavLink exact to='/clubInformationEEE'>Clubs Information</NavLink>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown text='Academic' className='link item sideMenuBarDropdown'>
                    <Dropdown.Menu>
                        {/* Programs */}
                        <Dropdown text='Programs' className='link item sideMenuBarDropdown'>
                            <Dropdown.Menu>
                                <Dropdown text='Undergraduate' className='link item'>
                                    <Dropdown.Menu>
                                        <Dropdown.Item>
                                            <NavLink exact to='/bscinEEEDepartmentProgram'>B.Sc in EEE</NavLink>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Dropdown.Menu>
                        </Dropdown>

                        {/* Admission */}
                        <Dropdown text='Admission' className='link item sideMenuBarDropdown'>
                            <Dropdown.Menu>
                                <Dropdown.Item>
                                    <NavLink exact to='/admissionEEEDepartment'>Undergraduate</NavLink>
                                </Dropdown.Item>

                            </Dropdown.Menu>
                        </Dropdown>
                        {/* Curriculum */}
                        <Dropdown text='Curriculum' className='link item sideMenuBarDropdown'>
                            <Dropdown.Menu>
                                <Dropdown.Item>
                                    <NavLink exact to='/underGraduateCurriculumEEE'>Undergraduate</NavLink>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown text='People' className='link item sideMenuBarDropdown'>
                    <Dropdown.Menu>
                        {/* Faculty Members */}
                        <Dropdown text='Faculty Members' className='link item sideMenuBarDropdown'>
                            <Dropdown.Menu>
                                <Dropdown.Item>
                                    <NavLink exact to='/atAGlanceFacultyMembersEEE'>At A Glance</NavLink>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <NavLink exact to='/currentFacultyMembersEEE'>Current Faculty </NavLink>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <NavLink exact to='/adjunctCurrentFacultyMembersEEE'>Adjunct Faculty</NavLink>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <NavLink exact to='/onLeaveFacultyMembersEEE'>On Leave</NavLink>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        {/* Officials */}
                        <Dropdown.Item>
                            <NavLink exact to='/officialsEEE'>Officials</NavLink>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                {/* Lab */}
                <Menu.Item className='sideBarSingleItem'>
                    <NavLink exact to='/labFacilitiesEEE'>Lab Facilities</NavLink>
                </Menu.Item>


                <Dropdown text='Research' className='link item sideMenuBarDropdown'>
                    <Dropdown.Menu>
                        <Dropdown.Item>
                            <NavLink exact to='/publicationsEEE'>Publications</NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <NavLink exact to='/researchGroupEEE'>Research Groups</NavLink>
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>

                {/* Announcement */}
                <Dropdown text='Announcement' className='link item sideMenuBarDropdown'>
                    <Dropdown.Menu>
                        <Dropdown.Item>
                            <NavLink exact to='/newsEEE'>News</NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <NavLink exact to='/upcomingEvents'>Upcoming Events</NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <NavLink exact to='/noticesEEE'>Notice</NavLink>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Menu.Item className='sideBarSingleItem'>
                    <NavLink exact to='/alumniEEE'>Alumni</NavLink>
                </Menu.Item>
                <Menu.Item className='sideBarSingleItemLast'>
                    <NavLink exact to='/contactUsEEE'>Contact Us</NavLink>
                </Menu.Item>
            </Menu >

        </div >
    );
};

export default SideBar;